.isumio-modal {
	width: 100%;
	max-width: 80%;
	max-height: 80%;
	min-height: 20rem;
	position: relative;
	overflow: auto;
}

.isumio-overlay {
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.6);
}

.isumio-closebutton {
	position: absolute;
	right: 1rem;
	top: 1rem;
}

.isumio-modal--small {
	max-width: 40rem;
}
