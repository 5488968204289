@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono');
@import 'react-tooltip/dist/react-tooltip.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

.main {
	background-color: #374654;
	height: 100vh;
}
